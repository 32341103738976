/** Authentication and dashboard  */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESSFULL = "LOGIN_SUCCESSFULL";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_USER = "LOGOUT_USER";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";

export const FORGOTPASSWORD_USER = "FORGOTPASSWORD_USER";
export const FORGOTPASSWORD_SUCCESSFULL = "FORGOTPASSWORD_SUCCESSFULL";
export const FORGOTPASSWORD_FAIL = "FORGOTPASSWORD_FAIL";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESSFULL = "CHANGE_PASSWORD_SUCCESSFULL";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const RESETPASSWORD_USER = "RESETPASSWORD_USER";
export const RESETPASSWORD_SUCCESSFULL = "RESETPASSWORD_SUCCESSFULL";
export const RESETPASSWORD_FAIL = "RESETPASSWORD_FAIL";

export const EDIT_ADMIN_PROFILE = "EDIT_ADMIN_PROFILE";
export const EDIT_ADMIN_PROFILE_SUCCESS = "EDIT_ADMIN_PROFILE_SUCCESS";
export const EDIT_ADMIN_PROFILE_FAIL = "EDIT_ADMIN_PROFILE_FAIL";

export const VALIDATETOKEN_USER = "VALIDATETOKEN_USER";
export const VALIDATETOKEN_SUCCESSFULL = "VALIDATETOKEN_SUCCESSFULL";
export const VALIDATETOKEN_FAIL = "VALIDATETOKEN_FAIL";

export const DASHBOARD = "DASHBOARD";
export const DASHBOARD_SUCCESSFULL = "DASHBOARD_SUCCESSFULL";
export const DASHBOARD_FAIL = "DASHBOARD_FAIL";

/** Action for Category management */
export const GET_MASTER_CATEGORY = "GET_MASTER_CATEGORY";
export const GET_MASTER_CATEGORY_SUCCESS = "GET_MASTER_CATEGORY_SUCCESS";
export const GET_MASTER_CATEGORY_FAIL = "GET_MASTER_CATEGORY_FAIL";
export const LIST_CATEGORY = "LIST_CATEGORY";
export const LIST_CATEGORY_SUCCESS = "LIST_CATEGORY_SUCCESS";
export const LIST_CATEGORY_FAIL = "LIST_CATEGORY_FAIL";

export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL";

export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
export const EDIT_CATEGORY_FAIL = "EDIT_CATEGORY_FAIL";

export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";
export const RESET_CATEGORY_NOTIFICATION = "RESET_CATEGORY_NOTIFICATION";

/** Action for user management */
export const LIST_USER = "LIST_USER";
export const LIST_USER_SUCCESS = "LIST_USER_SUCCESS";
export const LIST_USER_FAIL = "ADD_CATEGORY_FAIL";

export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAIL = "EDIT_USER_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const SET_EDIT_USER = "SET_EDIT_USER";
export const SET_EDIT_USER_SUCCESS = "SET_EDIT_USER_SUCCESS";
export const SET_EDIT_USER_FAIL = "SET_EDIT_USER_FAIL";

/** Sub category Management */
export const FETCH_SUBCATEGORY_MANAGEMENT = "FETCH_SUBCATEGORY_MANAGEMENT";
export const FETCH_SUBCATEGORY_MANAGEMENT_SUCCESS = "FETCH_SUBCATEGORY_MANAGEMENT_SUCCESS";
export const FETCH_SUBCATEGORY_MANAGEMENT_FAIL = "FETCH_SUBCATEGORY_MANAGEMENT_FAIL";

export const CHANGE_SUBCATEGORY_STATUS = "CHANGE_SUBCATEGORY_STATUS";
export const CHANGE_SUBCATEGORY_STATUS_SUCCESS = "CHANGE_SUBCATEGORY_STATUS_SUCCESS";
export const CHANGE_SUBCATEGORY_STATUS_FAIL = "CHANGE_SUBCATEGORY_STATUS_FAIL";

export const ADD_SUBCATEGORY = "ADD_SUBCATEGORY";
export const ADD_SUBCATEGORY_SUCCESS = "ADD_SUBCATEGORY_SUCCESS";
export const ADD_SUBCATEGORY_FAIL = "ADD_SUBCATEGORY_FAIL";

export const UPDATE_SUBCATEGORY = "UPDATE_CATEGORY";
export const UPDATE_SUBCATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_SUBCATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";
export const RESET_USER_NOTIFICATION = "RESET_USER_NOTIFICATION";
export const RESET_SUBCATEGORY_NOTIFICATION = "RESET_SUBCATEGORY_NOTIFICATION";

/** Active Devotee Actions */
export const LIST_ACTIVEDEVOTEE = "LIST_ACTIVEDEVOTEE";
export const LIST_ACTIVEDEVOTEE_SUCCESS = "LIST_ACTIVEDEVOTEE_SUCCESS";
export const LIST_ACTIVEDEVOTEE_FAIL = "LIST_ACTIVEDEVOTEE_FAIL";
export const LIST_ACTIVEDEVOTEE_STATISTICS = "LIST_ACTIVEDEVOTEE_STATISTICS";
export const LIST_ACTIVEDEVOTEE_STATISTICS_SUCCESS = "LIST_ACTIVEDEVOTEE_STATISTICS_SUCCESS";
export const LIST_ACTIVEDEVOTEE_STATISTICS_FAIL = "LIST_ACTIVEDEVOTEE_STATISTICS_FAIL";
export const RESET_ACTIVEDEVOTEE_NOTIFICATION = "RESET_ACTIVEDEVOTEE_NOTIFICATION";



export const FETCH_COUNTRIES = "FETCH_COUNTRIES"
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS"
export const FETCH_COUNTRIES_FAIL = "FETCH_COUNTRIES_FAIL"

export const FETCH_STATES = "FETCH_STATES"
export const FETCH_STATES_SUCCESS = "FETCH_STATES_SUCCESS"
export const FETCH_STATES_FAIL = "FETCH_STATES_FAIL"