import React, { Component } from "react";
import { connect } from 'react-redux';
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { listCategory,addCategory,editCategory,deleteCategory } from '../../redux/action'

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};


class CategoryList extends Component{
    constructor(props){
        super(props);
        this.setAddCategoryModal = this.setAddCategoryModal.bind(this);
        this.setEditCategoryModal = this.setEditCategoryModal.bind(this);
        this.setCategoryname = this.setCategoryname.bind(this);
        this.setCategorynameState = this.setCategorynameState.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.state = { 
            data : [],
            categories : [],
            totalcategory : 0,
            page : 0,
            pagesize : 5,
            addCategoryModal: false,
            iseditModal : false,
            categoryIdModal: 0,
            categoryName: "",
            categoryNameState : "",
            alert: null
        }
    }
    componentDidMount(){
      // this.setData(dataTable);
    }
    getDataFromDb(state){
      let sorted,order;
      let filtered = {}
      if(state.sorted.length > 0){
        sorted = state.sorted[0].id
      }
      if(state.sorted.length > 0){
        if(state.sorted[0].desc === false){
          order = 1
        }else{
          order = -1
        }
      }
      if(state.filtered.length > 0){
        state.filtered.forEach(element => {
          filtered[element.id] = element.value;
        });
      }

      let admin_id = this.props.user.user_id
      console.log('admin_id',this.props.user);
      this.props.listCategory({
        admin_id:admin_id,
        page: state.page,
        page_size: state.pageSize,
        sort_param: sorted,
        order: order,
        filtred : filtered
      },this.props.history);
    }
    static getDerivedStateFromProps(props, state) {
      // ...
      // console.log("props cattt",props);
      // console.log("props state cattt",state);
      // if (props.id !== state.prevId) {        
        return {        
          categories: props.categories,
          totalcategory : props.total_category          
        };   
      // }    
      // No state update necessary   
      // return null;    
    }
    setCategoryname(val=""){
        this.setState({categoryName:val});
    }
    setCategorynameState(val=""){
        this.setState({categoryNameState:val});
    }
    setAddCategoryModal(val=false){
        this.setState({addCategoryModal:val,iseditModal:false,categoryIdModal:0,categoryName:""})
    }
    setEditCategoryModal(val="",id=0){
      this.setState({
        addCategoryModal: true,
        iseditModal : true,
        categoryName : val,
        categoryIdModal : id
      })
    }
    setAlert(val=null){
      this.setState({alert:val})
    }
    verifyCategoryname(val){
        if(val.trim().length > 0){
            return true;
        }
        return false;
    }
    addCategory(){
      let admin_id = this.props.user.user_id;
        if(this.state.categoryName.trim() === ""){
            this.setCategorynameState("error");
        }
        let cat = {admin_id,category_name:this.state.categoryName,id:this.state.categoryIdModal}
        if(this.state.iseditModal){
          this.props.editCategory(cat,this.props.history);
        }else{
          this.props.addCategory(cat,this.props.history);
        }
        this.setCategoryname();
        this.setAddCategoryModal();
        this.setState({iseditModal:false,categoryIdModal:0})
        this.getDataFromDb({page: this.state.page,pageSize: this.state.pagesize,sorted:[],filtered:[]});
    }  
    deleteCategory(id){
      let admin_id = this.props.user.user_id;
      this.props.deleteCategory({admin_id,id,status: 2},this.props.history);
      this.setAlert();
      this.getDataFromDb({page: this.state.page,pageSize: this.state.pagesize,sorted:[],filtered:[]});
    }
    warningWithConfirmAndCancelMessage(id){
      let ths = this;
      const { classes } = this.props;
      this.setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Do you want to delete it, Sure?"
          onConfirm={() => ths.deleteCategory(id)}
          onCancel={() => ths.setAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
        >          
        </SweetAlert>
      );
    };
    createTablecontent(data){
        let ths = this;
        return data.map((prop, key) => {
            return {
              sr_no : (this.state.pagesize * this.state.page)+key+1,
              category_id: prop['category_id'],
              category_name: prop['category_name'],              
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to add a like kind of action */}
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      ths.setEditCategoryModal(prop['category_name'],prop['category_id'])
                    }}
                    color="info"
                    className="like"
                  >
                    <Edit />
                  </Button>{" "}
                  {/* use this button to remove the data row */}
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      this.warningWithConfirmAndCancelMessage(prop['category_id']);
                      // var newData = this.state.data;
                      // newData.find((o, i) => {
                      //   if (o.category_id === prop['category_id']) {
                      //     // here you should add some custom code so you can delete the data
                      //     // from this component and from your server as well
                      //     newData.splice(i, 1);
                      //     return true;
                      //   }
                      //   return false;
                      // });
                    }}
                    color="danger"
                    className="remove"
                  >
                    <Close />
                  </Button>{" "}
                </div>
              )
            };
          })
    }
    render(){
      console.log("lete",this.state)      
        // let { data } = this.state;
        const { classes } = this.props;
        return (
            <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                <GridContainer>
                <GridItem xs={9} md={9} lg={9}>
                  <CardIcon color="primary">
                    <Assignment />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Categories</h4>.
                  </GridItem>
                  <GridItem xs={3} md={3} lg={3}>
                  <Button
                    color="info"
                    round
                    className={classes.marginRight}
                    onClick={() => this.setAddCategoryModal(true)}
                    >
                        Add new Category
                    </Button>
                    </GridItem>
                    </GridContainer>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.createTablecontent(this.state.categories)}
                    filterable
                    columns={[
                      {
                        Header : "Sr No.",
                        accessor : "sr_no"
                      },
                      {
                        Header: "Category Name",
                        accessor: "category_name"
                      },                      
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false
                      }
                    ]}
                    defaultPageSize={(this.state.categories.length<this.state.pagesize)?((this.state.categories.length > 0)?this.state.categories.length:this.state.pagesize):this.state.pagesize}
                    pages={Math.ceil(this.state.totalcategory/this.state.pagesize)}               
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    manual
                    onFetchData={(state, instance) => {
                      // show the loading overlay                        
                      // fetch your data   
                      console.log("STATEL:",state) 
                      this.setState({page:state.page,pagesize : state.pageSize})
                      this.getDataFromDb(state);
                    }}
                  />
                </CardBody>
              </Card>
            </GridItem>
            {/* <GridContainer justify="center"> */}
                  <GridItem xs={12} sm={12} md={12} className={classes.center}>                    
                    <Dialog
                        classes={{
                            root: classes.center + " " + classes.modalRoot,
                            paper: classes.modal
                        }}
                        open={this.state.addCategoryModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => this.setAddCategoryModal(false)}
                        aria-labelledby="notice-modal-slide-title"
                        aria-describedby="notice-modal-slide-description"
                    >
                        <DialogTitle
                            id="notice-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                        >
                            <Button
                                justIcon
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="transparent"
                                onClick={() => this.setAddCategoryModal(false)}
                            >
                            {/* <h4 className={classes.modalTitle}>Add Category</h4> */}
                            <Close className={classes.modalClose} />
                            </Button>
                        </DialogTitle>
                        <DialogContent
                            id="notice-modal-slide-description"
                            className={classes.modalBody}
                        >
                            <Card>
                                <CardHeader color="rose" icon>
                                    {/* <CardIcon color="rose">
                                    <Contacts />
                                    </CardIcon> */}
                                    <h4 className={classes.cardIconTitle}>{(this.state.iseditModal)?'Edit Category':'Add Category'}</h4>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                    <CustomInput
                                        success={this.state.categoryNameState === "success"}
                                        error={this.state.categoryNameState === "error"}
                                        labelText="Category"
                                        id="category"
                                        formControlProps={{
                                        fullWidth: true
                                        }}
                                        inputProps={{
                                        value : this.state.categoryName,
                                        onChange: event => {
                                            if (this.verifyCategoryname(event.target.value)) {
                                                this.setCategorynameState("success");
                                            } else {
                                                this.setCategorynameState("error");
                                            }
                                            this.setCategoryname(event.target.value);
                                        },
                                        type: "text"
                                        }}
                                    />                                    
                                    <div className={classes.formCategory}>
                                        <small>*</small> Required fields
                                    </div>
                                    <div className={classes.center}>
                                        <Button color="rose" onClick={this.addCategory}>
                                        {(this.state.iseditModal)?'Edit':'Add'}
                                        </Button>
                                    </div>
                                    </form>
                                </CardBody>
                                </Card>

                        </DialogContent>
                        {/* <DialogActions
                            className={
                            classes.modalFooter + " " + classes.modalFooterCenter
                            }
                        >
                            <Button
                            onClick={() => this.setAddCategoryModal(false)}
                            color="info"
                            round
                            >
                            Sounds Good
                            </Button>
                        </DialogActions> */}
                    </Dialog>
                      
                  </GridItem>
            {/* </GridContainer> */}
            {this.state.alert}
            <Snackbar
              place="tr"
              color={(this.props.category_error)?"danger":"info"}
              icon={AddAlert}
              message={`${this.props.notification_message}`}
              open={this.props.shownotification}
              closeNotification={() =>{
                          
                }}
              close
            />
          </GridContainer> 
        )
    }
}
const mapStateToProps = state => {
  console.log('in maptoprops:',state);
  
  return {
    user: state.authReducer.user,
    shownotification: state.categoryReducer.shownotification,
    category_error : state.categoryReducer.category_error,    
    categories : state.categoryReducer.categories,
    total_category : state.categoryReducer.total_category,
    notification_message:state.categoryReducer.notification_message
  };
};
const mapDispatchToProps = { listCategory,addCategory,editCategory,deleteCategory };
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(CategoryList))