import React, { Component } from "react";
import { connect } from 'react-redux';
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";

// material ui icons
import Contacts from "@material-ui/icons/Contacts";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { addUser, editUser, fetchCountries, fetchStates } from '../../redux/action';

import FormControl from "@material-ui/core/FormControl";
// style for this view
import { validationFormsStyle } from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { customSelectStyle } from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const styles = {
    ...validationFormsStyle,
    ...customSelectStyle

};



class UserAddEdit extends Component {
    constructor(props) {
        super(props);
        console.log("IN STATE")
        this.addUser = this.addUser.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.state = {
            edit_user_obj: null,
            isEditPage: false,
            countries: [],
            states: [],
            firstName: "",
            firstNameState: "",
            lastName: "",
            lastNameState: "",
            email: "",
            emailState: "",
            phone: "",
            phoneState: "",
            address: "",
            addressState: "",
            password: "",
            passwordState: "",
            user_id: 0,
            alert: null,
            country: 0,
            country_state: "",
            state: 0,
            state_state: ""
        }
    }
    static getDerivedStateFromProps(props, state) {
        return {
            countries: (props.countries) ? props.countries : [],
            states: (props.states) ? props.states : []
        };
    }
    componentDidMount() {
        console.log("IN MOUNT:", this.props)
        this.setState({
            isEditPage: true
        })


        this.props.fetchCountries({ "type": "countries" });
        let country_id = (this.props.edit_user_obj) ? (this.props.edit_user_obj['country'] ? this.props.edit_user_obj['country'] : "") : ""
        if (country_id !== "") {
            this.props.fetchStates({ "type": "states", "id": country_id });
            
        }
        this.setState({
            users: (this.props.edit_user_obj) ? this.props.users : [],
            // isEditPage : (this.props.edit_user_obj)?true:false,
            firstName: (this.props.edit_user_obj) ? (this.props.edit_user_obj['first_nameonly'] ? this.props.edit_user_obj['first_nameonly'] : "") : "",
            lastName: (this.props.edit_user_obj) ? (this.props.edit_user_obj['last_name'] ? this.props.edit_user_obj['last_name'] : "") : "",
            email: (this.props.edit_user_obj) ? (this.props.edit_user_obj['email'] ? this.props.edit_user_obj['email'] : "") : "",
            phone: (this.props.edit_user_obj) ? (this.props.edit_user_obj['phone'] ? this.props.edit_user_obj['phone'] : "") : "",
            address: (this.props.edit_user_obj) ? (this.props.edit_user_obj['address'] ? this.props.edit_user_obj['address'] : "") : "",
            user_id: (this.props.edit_user_obj) ? (this.props.edit_user_obj['id'] ? this.props.edit_user_obj['id'] : 0) : 0,
            country: (this.props.edit_user_obj) ? (this.props.edit_user_obj['country'] ? this.props.edit_user_obj['country'] : 0) : 0,
            state: (this.props.edit_user_obj) ? (this.props.edit_user_obj['state'] ? this.props.edit_user_obj['state'] : 0) : 0

        })
    }
    //  static getDerivedStateFromProps(props, state) {    
    //     console.log("propsssss11111",props.edit_user_obj)
    //     let country_id = (props.edit_user_obj) ? (props.edit_user_obj['country'] ? props.edit_user_obj['country'] : ""):""
    //     if (country_id !== "" && state.states.length == 0) {
    //         console.log("stattttttttcallllllllllllllllllll")
    //         props.fetchStates({ "type": "states", "id": country_id });
    //     }
    //     var firsname11 = (props.edit_user_obj)?(props.edit_user_obj['first_name']?props.edit_user_obj['first_name']:""):"";
    //     var  lastName11 = (props.edit_user_obj)?(props.edit_user_obj['last_name']?props.edit_user_obj['last_name']:""):"";           
    //     var  email11 = (props.edit_user_obj)?(props.edit_user_obj['email']?props.edit_user_obj['email']:""):"";       
    //     var  phone11 = (props.edit_user_obj)?(props.edit_user_obj['phone']?props.edit_user_obj['phone']:""):"";
    //     var  address11= (props.edit_user_obj)?(props.edit_user_obj['address']?props.edit_user_obj['address']:""):"";           
    //     var user_id11 = (props.edit_user_obj)?(props.edit_user_obj['user_id']?props.edit_user_obj['user_id']:0):0;   
    //     var country11= (props.edit_user_obj) ? (props.edit_user_obj['country'] ? props.edit_user_obj['country'] : 0) : 0;
    //     var  state11= (props.edit_user_obj) ? (props.edit_user_obj['state'] ? props.edit_user_obj['state'] : 0) : 0;
    //     return{
    //         users: (props.edit_user_obj)?props.users:[],

    //         firstName : (firsname11 != state.firstName && state.firstName != '')?state.firstName:firsname11,            
    //         lastName : (lastName11 != state.lastName && state.lastName != '')?state.lastName:lastName11,           
    //         email : email11,        
    //         phone: (phone11 != state.phone && state.phone != '')?state.phone:phone11, 
    //         address: (address11 != state.address && state.address != '')?state.address:address11,        
    //         user_id : user_id11, 
    //         country: (country11 != state.country && state.country != '')?state.country:country11, 
    //         state: (state11 != state.state  && state.state != '')?state.state:state11, 
    //         states: (props.states)?((props.states.length > 0)?props.states:[]):[]
    //     }

    //   }
    setAlert(val = null) {
        this.setState({ alert: val })
    }
    verifyString(val, len = 0) {


        if (val.trim().length >= len) {
            return true
        }
        return false;
    }
    verifyPhone(val, len = 0) {
        console.log("phone:", isNaN(val.trim()));

        if (val.trim().length >= len && (!isNaN(val.trim()))) {
            return true
        }
        return false;
    }

    setfirstName(val = "") {
        this.setState({ firstName: val })
    }
    setfirstNameState(val = "") {
        this.setState({ firstNameState: val })
    }

    setlastName(val = "") {
        this.setState({ lastName: val })
    }
    setlastNameState(val = "") {
        this.setState({ lastNameState: val })
    }

    setemail(val = "") {
        this.setState({ email: val })
    }
    setemailState(val = "") {
        this.setState({ emailState: val })
    }
    verifyEmail(val) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(val)) {
            return true;
        }
        return false;
    }

    setphone(val = "") {
        this.setState({ phone: val })
    }
    setphoneState(val = "") {
        this.setState({ phoneState: val })
    }
    setaddress(val = "") {
        this.setState({ address: val })
    }
    setaddressState(val = "") {
        this.setState({ addressState: val })
    }
    setcountry(val = "") {
        this.setState({ country: val })
    }
    setcountry_state(val = "") {
        this.setState({ country_state: val })
    }

    setstate(val = "") {
        this.setState({ state: val })
    }
    setstate_state(val = "") {
        this.setState({ state_state: val })
    }
    setemailState(val = "") {
        this.setState({ emailState: val })
    }
    handleCountryChange = (country) => {
        console.log("Country Change", country)
        this.props.fetchStates({ "type": "states", "id": country.target.value });
        this.setState({ country: country.target.value,state:'' })
    }

    handleStateChange = (state) => {
        console.log("State Change", state)
        this.setState({ state: state.target.value })
        // this.setState({ contentLanguage: event, content, meaning, meaningLanguage: event })
    }
    setpassword(val = "") {
        this.setState({ password: val })
    }
    setpasswordState(val = "") {
        this.setState({ passwordState: val })
    }

    cancelEdit() {
        this.props.history.push("/admin/user-management/list")
    }

    addUser() {
        console.log("State:0", this.state);
        console.log('first', this.verifyString(this.state.firstName, 2), 'last', this.verifyString(this.state.lastName, 2), 'phone', this.verifyString(this.state.phone, 8), 'email', this.verifyEmail(this.state.email), 'pass', (this.verifyString(this.state.password, 8) && (!this.state.isEditPage)));
        let { country, state } = this.state;
        // if (this.verifyString(this.state.firstName, 2) || this.verifyString(this.state.firstName, 0)) {
        //     this.setfirstNameState("error");
        // }

        // if (this.verifyString(this.state.lastName, 2) || this.verifyString(this.state.lastName, 0)) {
        //     this.setlastNameState("error");
        // }
        // if (this.verifyString(this.state.phone, 8) || this.verifyString(this.state.phone, 0)) {
        //     this.setphoneState("error");
        // }
        // if (this.verifyString(this.state.address, 2) || this.verifyString(this.state.address, 0)) {
        //     this.setaddressState("error");
        // }
        // if (this.state.country == "" || this.state.country == 0) {
        //     this.setcountry_state("error");
        // }
        // if (this.verifyEmail(this.state.email)) {
        //     this.setemailState("error");
        // }
        if (this.state.isEditPage) {
            if (this.verifyString(this.state.password, 8)) {
                this.setpasswordState("error");
            }
        }
        // && (this.verifyString(this.state.password,8) && (!this.state.isEditPage))
        if (this.verifyEmail(this.state.email)) {
        //if (this.verifyString(this.state.firstName, 2) && this.verifyString(this.state.lastName, 2) && this.verifyString(this.state.phone, 8) && this.verifyString(this.state.address, 2) && this.state.country !== "" && this.state.state !== "" && this.state.country !== 0 && this.state.state !== 0 && this.verifyEmail(this.state.email)) {
            let admin_id = this.props.user.user_id
            let user = {
                admin_id,
                'id': this.state.user_id,
                'first_name': this.state.firstName,
                'last_name': this.state.lastName,
                'phone': this.state.phone,
                'email': this.state.email,
                'address': this.state.address,
                'password': this.state.password,
                'country': this.state.country,
                'state': this.state.state
            }
            console.log("USER OBJECT:", user);

            if (this.state.isEditPage === true) {

                this.props.editUser(user, this.props.history);

            } else {
                this.props.addUser(user, this.props.history);
                this.setState({
                    isEditPage: false,
                    firstName: "",
                    firstNameState: "",
                    lastName: "",
                    lastNameState: "",
                    email: "",
                    emailState: "",
                    phone: "",
                    phoneState: "",
                    password: null,
                    passwordState: ""

                })
            }
        }
    }

    render() {
        const { classes } = this.props;

        console.log("userfirstname", this.props.edit_user_obj)
        const { countries, country, state, states } = this.state;
        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <Contacts />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{(this.state.isEditPage === false) ? "Add User" : "Edit User"}</h4>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <CustomInput
                                    success={this.state.firstNameState === "success"}
                                    error={this.state.firstNameState === "error"}
                                    labelText="First Name"
                                    id="firstname"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            if (this.verifyString(event.target.value, 0)) {
                                                this.setfirstNameState("success");
                                            } else {
                                                this.setfirstNameState("error");
                                            }
                                            this.setfirstName(event.target.value);
                                        },
                                        value: this.state.firstName,
                                        type: "text"
                                    }}
                                />
                                <CustomInput
                                    success={this.state.lastNameState === "success"}
                                    error={this.state.lastNameState === "error"}
                                    labelText="Last Name"
                                    id="lastname"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            if (this.verifyString(event.target.value, 0)) {
                                                this.setlastNameState("success");
                                            } else {
                                                this.setlastNameState("error");
                                            }
                                            this.setlastName(event.target.value);
                                        },
                                        value: this.state.lastName,
                                        type: "text"
                                    }}
                                />
                                <CustomInput
                                    success={this.state.emailState === "success"}
                                    error={this.state.emailState === "error"}
                                    labelText="Email"
                                    id="email"

                                    formControlProps={{
                                        fullWidth: true,
                                        disabled: true,
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            if (this.verifyEmail(event.target.value)) {
                                                this.setemailState("success");
                                            } else {
                                                this.setemailState("error");
                                            }
                                            this.setemail(event.target.value);
                                        },
                                        value: this.state.email,
                                        type: "email"
                                    }}
                                />
                                <CustomInput
                                    success={this.state.phoneState === "success"}
                                    error={this.state.phoneState === "error"}
                                    labelText="Phone"
                                    id="phone"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            if (this.verifyPhone(event.target.value, 0)) {
                                                this.setphoneState("success");
                                            } else {
                                                this.setphoneState("error");
                                            }
                                            this.setphone(event.target.value);
                                        },
                                        value: this.state.phone,
                                        type: "text"
                                    }}
                                />
                                
                                <FormControl fullWidth className={classes.selectFormControl}>
                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu,
                                            fullWidth: true
                                        }}
                                        classes={{
                                            select: classes.select
                                        }}
                                        onChange={this.handleCountryChange}
                                        inputProps={{
                                            name: "country",
                                            id: "country",
                                            value: country
                                        }}
                                    >
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={0}
                                        >
                                            Select Country
                            </MenuItem>
                                        {(this.state.countries.length > 0) ? this.state.countries.map((country) => <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={country.id}
                                        >
                                            {country.name}
                                        </MenuItem>) : []}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth className={classes.selectFormControl}>
                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu,
                                            fullWidth: true
                                        }}
                                        classes={{
                                            select: classes.select
                                        }}
                                        value={this.state.state}
                                        onChange={this.handleStateChange}
                                        inputProps={{
                                            name: "state",
                                            id: "state"
                                        }}
                                    >
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={0}
                                        >
                                            Select State
                                                </MenuItem>
                                        {(this.state.states.length > 0) ? this.state.states.map((state) => <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={state.id}
                                        >
                                            {state.name}
                                        </MenuItem>) : []}
                                    </Select>
                                </FormControl>
                                <CustomInput
                                    success={this.state.addressState === "success"}
                                    error={this.state.addressState === "error"}
                                    labelText="City"
                                    id="address"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            if (this.verifyString(event.target.value)) {
                                                this.setaddressState("success");
                                            } else {
                                                this.setaddressState("error");
                                            }
                                            this.setaddress(event.target.value);
                                        },
                                        value: this.state.address,
                                        type: "text"
                                    }}
                                />
                                {(this.state.isEditPage === false) ? <CustomInput
                                    success={this.state.passwordState === "success"}
                                    error={this.state.passwordState === "error"}
                                    labelText="Password *"
                                    id="password"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            if (this.verifyString(event.target.value, 8)) {
                                                this.setpasswordState("success");
                                            } else {
                                                this.setpasswordState("error");
                                            }
                                            this.setpassword(event.target.value);
                                        },
                                        value: this.state.password,
                                        type: "password"
                                    }}
                                /> : null}
                                {/* <div className={classes.formCategory}>
                                    <small>*</small> Required fields
                        </div> */}
                                <div className={classes.center}>
                                    <Button color="rose" onClick={this.addUser}>
                                        {(this.state.isEditPage === false) ? "Add User" : "Edit User"}
                                    </Button>{" "}
                                    <Button color="white" onClick={this.cancelEdit}>
                                        Cancel
                            </Button>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
                <Snackbar
                    place="tr"
                    color={(this.props.user_error) ? "danger" : "info"}
                    icon={AddAlert}
                    message={`${this.props.notification_message}`}
                    open={this.props.shownotification}
                    closeNotification={() => {

                    }}
                    close
                />
            </GridContainer>
        )
    }
}

const mapStateToProps = state => {
    console.log('in maptoprops111:', state.userReducer.edit_user_obj);

    return {
        user: state.authReducer.user,
        shownotification: state.userReducer.shownotification,
        user_error: state.userReducer.user_error,
        countries: state.userReducer.countries,
        states: state.userReducer.states,
        edit_user_id: state.userReducer.edit_user_id,
        edit_user_obj: state.userReducer.edit_user_obj,
        total_user: state.userReducer.total_user,
        notification_message: state.userReducer.notification_message
    };
};
const mapDispatchToProps = { addUser, editUser, fetchCountries, fetchStates };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserAddEdit))