import { combineReducers } from 'redux';
import authReducer from './auth/authreducer';
import categoryReducer from './categorymanagement/categoryreducer';
import userReducer  from './usermanagement/userreducer';
import subcategory from './subcategorymanagement/subcategoryreducer';
import activedevoteeReducer from './activedevotee/activedevoteereducer';

const reducers = combineReducers({
    authReducer,
    categoryReducer,
    userReducer,
    subcategory,
    activedevoteeReducer 
})

export default reducers;