import Dashboard from "views/Dashboard/Dashboard.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import ForgotPasswordPage from "views/Pages/ForgotPasswordPage.js";
import ResetPasswordPage from "views/Pages/ResetPasswordPage.js";
import ChangePasswordPage from "views/Admin/ChangePasswordPage.js";
import AdminProfile from "views/Admin/AdminProfile.js";

import SubCategoryList from "views/SubcategoryManagement/List.js";
import CategoryList from "views/CategoryManagement/List.js";
import UserList from "views/UserManagement/List.js";
import UserAddEdit from "views/UserManagement/AddEdit.js"


import RegisterPage from "views/Pages/RegisterPage.js";
import TimelinePage from "views/Pages/Timeline.js";
import UserProfile from "views/Pages/UserProfile.js";
import ActiveDevotee from "views/ActiveDevotee/ActiveDevotee.js"
import ActiveDevoteeStatistics from "views/ActiveDevotee/ActiveDevoteeStatistics.js"

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Image from "@material-ui/icons/Image";
import PeopleIcon from '@material-ui/icons/People';
import CategoryIcon from '@material-ui/icons/Category';
import SubCategoryIcon from "@material-ui/icons/Subtitles"

//changesv1
var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/change-password",
    name: "Change Password",
    rtlName: "لوحة القيادة",
    mini: "CP",
    component: ChangePasswordPage,
    layout: "/admin",
    invisible:true
  },
  {
    path: "/edit-profile",
    name: "Edit Profile",
    rtlName: "لوحة القيادة",
    mini: "EP",
    component: AdminProfile,
    layout: "/admin",
    invisible : true
  },
 
  {
    path: "/user-management/list",
    name: "User Management",
    rtlName: "لوحة القيادة",
    icon: PeopleIcon,
    component: UserList,
    layout: "/admin"
  },
  {
    path: "/user-management/edit",
    name: "Edit User",
    rtlName: "لوحة القيادة",
    mini: "EU",
    component: UserAddEdit,
    layout: "/admin",
    invisible:true
  },
  {
    path: "/active-devotee",
    name: "Active Devotee",
    rtlName: "لوحة القيادة",
    icon: PeopleIcon,
    component: ActiveDevotee,
    layout: "/admin"    
  },
  {
    path: "/active-devotee-statistics",
    name: "Active Devotee Statistics",
    rtlName: "لوحة القيادة",
    mini: "ADS",
    component: ActiveDevoteeStatistics,
    layout: "/admin",
    invisible : true
  },
  
  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    invisible : true,
    views: [               
      {
        path: "/timeline-page",
        name: "Timeline Page",
        rtlName: "تيالجدول الزمني",
        mini: "T",
        rtlMini: "تي",
        component: TimelinePage,
        layout: "/admin"
      },
      {
        path: "/login",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth"
      },
      {
        path: "/forgot-password",
        name: "Forgot Password",
        rtlName: "",
        mini: "L",
        rtlMini: "",
        component: ForgotPasswordPage,
        layout: "/auth"
      },
      {
        path: "/reset-password",
        name: "Reset Password",
        rtlName: "",
        mini: "L",
        rtlMini: "",
        component: ResetPasswordPage,
        layout: "/auth"
      },
     
      {
        path: "/user-page",
        name: "User Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: UserProfile,
        layout: "/admin"
      },
      {
        path: "/error-page",
        name: "Error Page",
        rtlName: "صفحة الخطأ",
        mini: "E",
        rtlMini: "البريد",
        component: ErrorPage,
        layout: "/auth"
      }
    ]
  }   
];
export default dashRoutes;
