import { 
    LOGIN_USER,
    LOGIN_SUCCESSFULL,
    LOGIN_FAIL,
    LOGOUT_USER,
    RESET_NOTIFICATION,
    FORGOTPASSWORD_USER,
    FORGOTPASSWORD_SUCCESSFULL,
    FORGOTPASSWORD_FAIL,
    RESETPASSWORD_USER,
    RESETPASSWORD_SUCCESSFULL,
    RESETPASSWORD_FAIL,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_FAIL,
    CHANGE_PASSWORD_SUCCESSFULL,
    VALIDATETOKEN_USER,
    VALIDATETOKEN_SUCCESSFULL,
    VALIDATETOKEN_FAIL,
    DASHBOARD,
    DASHBOARD_SUCCESSFULL,
    DASHBOARD_FAIL,
    EDIT_ADMIN_PROFILE_FAIL,
    EDIT_ADMIN_PROFILE_SUCCESS,
    EDIT_ADMIN_PROFILE,
 } from '../actionconstant';


export const loginUser =(user,history)=>({
    type: LOGIN_USER,
    payload: { user,history }
})

export const loginSuccessfull =(user)=>({
    type: LOGIN_SUCCESSFULL,
    payload: user
})

export const loginFail =(data)=>({
    type: LOGIN_FAIL,
    payload: data 
})

export const resetNotification=()=>({
    type:RESET_NOTIFICATION
})

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload : history
  });


export const forgotpasswordUser =(user,history)=>({
    type: FORGOTPASSWORD_USER,
    payload: { user,history }
})

export const forgotPasswordSuccessfull =(data)=>({
    type: FORGOTPASSWORD_SUCCESSFULL,
    payload: data
})

export const forgotPasswordFail =(data)=>({
    type: FORGOTPASSWORD_FAIL,
    payload: data 
})

export const changePassword = ( data,history ) => ({
    type : CHANGE_PASSWORD,
    payload: { data , history }
})

export const changePasswordSuccessfully = ( data ) => ({
    type : CHANGE_PASSWORD_SUCCESSFULL,
    payload:  data 
})

export const changePasswordFailed = ( data ) => ({
    type : CHANGE_PASSWORD_FAIL,
    payload:  data 
})

export const resetpasswordUser =(user,history)=>({
    type: RESETPASSWORD_USER,
    payload: { user,history }
})

export const resetPasswordSuccessfull =(data)=>({
    type: RESETPASSWORD_SUCCESSFULL,
    payload: data
})

export const resetPasswordFail =(data)=>({
    type: RESETPASSWORD_FAIL,
    payload: data 
})


export const validatetoken =(user,history)=>({
    type: VALIDATETOKEN_USER,
    payload: { user,history }
})

export const validatetokenSuccessfull =(data)=>({
    type: VALIDATETOKEN_SUCCESSFULL,
    payload: data
})

export const validatetokenFail =(data)=>({
    type: VALIDATETOKEN_FAIL,
    payload: data 
})

export const dashboard =(user,history)=>({
    type: DASHBOARD,
    payload: { user,history }
})

export const dashboardSuccessfull =(data)=>({
    type: DASHBOARD_SUCCESSFULL,
    payload: data
})

export const dashboardFail =(data)=>({
    type: DASHBOARD_FAIL,
    payload: data 
})

export const editAdminProfile =(user,history)=>({
    type: EDIT_ADMIN_PROFILE,
    payload: { user,history }
})

export const editAdminProfileSuccessfull =(user)=>({
    type: EDIT_ADMIN_PROFILE_SUCCESS,
    payload: user
})

export const editAdminProfileFail =(data)=>({
    type: EDIT_ADMIN_PROFILE_FAIL,
    payload: data 
})