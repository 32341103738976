import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';

// import InfoOutline from "@material-ui/icons/InfoOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody";
import PeopleIcon from '@material-ui/icons/People';
import Button from "components/CustomButtons/Button.js";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

import CardFooter from "components/Card/CardFooter.js";


import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { resetActiveDevoteeNotification,listActiveDevoteeStatistics } from '../../redux/action'



class ActiveDevoteeStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    
      activedevotee_statistics : [],
      activedevotee_count1 : 0,
      activedevotee_count2 : 0,   
      activedevotee_count3 : 0,
      activedevotee_count4 : 0,      
      notification: false
    }
  } 
  componentDidMount(){  
    let admin_id = this.props.user.user_id
    console.log('admin_id',this.props.user);
    this.props.listActiveDevoteeStatistics({
      admin_id:admin_id,
    },this.props.history);  
  } 


  render() {
    const { classes } = this.props;
   console.log("this.props.activedevotee_statistics1111",this.props.activedevotee_statistics)
    const { activedevotee_count1,activedevotee_count2,activedevotee_count3,activedevotee_count4 } = this.props;
    return (
       <div>
         
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
                  <NavLink to={"/admin/active-devotee"} className={classes.navLink}>
                            
                            <Button className={classes.marginRight}>
                            <KeyboardArrowLeft className={classes.icons} />  Back 
                            </Button>
                        </NavLink>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
            <CardBody color="rose" stats icon>
                <GridContainer>
                  <table width="100%">
                      <tr>
                        <th xs={6} sm={6} md={6} lg={6}></th>
                        <th xs={2} sm={2} md={2} lg={2} align="left" >Users</th> 
                        <th xs={2} sm={2} md={2} lg={2} align="left" >Mantras</th>
                      </tr>
                      <tr>
                        <td xs={6} sm={6} md={6} lg={6}>Active</td>
                        <td xs={2} sm={2} md={2} lg={2}>{activedevotee_count1}</td>
                        <td xs={2} sm={2} md={2} lg={2}>{activedevotee_count2}</td>
                      </tr>
                      <tr>
                        <td xs={6} sm={6} md={6} lg={6} className={classes.todaybottom}>Today</td>
                        <td xs={2} sm={2} md={2} lg={2}>{activedevotee_count3}</td>
                        <td xs={2} sm={2} md={2} lg={2}>{activedevotee_count4}</td>
                      </tr>
                      {this.props.activedevotee_statistics.map((person, index) => (
                          <div key={index} className={classes.statsdata}>
                            <tr >
                            <td xs={6} sm={6} md={6} lg={6} className={classes.countrydata}>{person.country}</td>
                            <td xs={2} sm={2} md={2} lg={2}>{person.countrywisesessioncount}</td>
                            <td xs={2} sm={2} md={2} lg={2}>{person.countrywisemantracount}</td>
                          </tr>
                          <tr >
                            <td xs={6} sm={6} md={6} lg={6}></td>
                            <td xs={2} sm={2} md={2} lg={2}></td>
                            <td xs={2} sm={2} md={2} lg={2}></td>
                          </tr>
                           {person.statedata.map((state, indexs) => (
                            <tr key={indexs}>
                              <td xs={6} sm={6} md={6} lg={6} className={classes.statename} >{state.state}</td>
                              <td xs={2} sm={2} md={2} lg={2}>{state.statewisesessioncount}</td>
                              <td xs={2} sm={2} md={2} lg={2}>{state.statewisemantracount}</td>
                            </tr>
                            ))}
                            </div>
                          
                         
                      ))}
                      
                       
                      
                      
                      
                      
                    </table>
                    </GridContainer>
              </CardBody>               
            </Card>
          </GridItem>
        </GridContainer>
       </div>
    );
  }
}

ActiveDevoteeStatistics.propTypes = {
  classes: PropTypes.object
};



 const mapStateToProps = state => {
  // console.log('in maptoprops:',state);
  
  return {
    user: state.authReducer.user,    
    activedevotee_statistics : state.activedevoteeReducer.activedevotee_statistics,    
    activedevotee_count1 : state.activedevoteeReducer.activedevotee_count1,
    activedevotee_count2 : state.activedevoteeReducer.activedevotee_count2,
    activedevotee_count3 : state.activedevoteeReducer.activedevotee_count3,
    activedevotee_count4 : state.activedevoteeReducer.activedevotee_count4,
    shownotification: state.authReducer.shownotification,
    activedevotee_error : state.authReducer.activedevotee_error,    
    notification_message:state.authReducer.notification_message
  };
};
const mapDispatchToProps = { resetActiveDevoteeNotification,listActiveDevoteeStatistics };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ActiveDevoteeStatistics));