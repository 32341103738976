import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import ReactTable from "react-table";

// import InfoOutline from "@material-ui/icons/InfoOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody";
import PeopleIcon from '@material-ui/icons/People';
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";

import bottonstyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { resetActiveDevoteeNotification,listActiveDevotee } from '../../redux/action'



class ActiveDevotee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        activedevotee_list : [],
        activedevotee_total_list_user : 0,
        activedevotee_total_user : 0,        
        notification: false,
        activedevotee : [],
        page : 0,
        pagesize : 25
    }
  } 
  componentDidMount(){  
  }

  getDataFromDb(state){
    let sorted,order;
    let filtered = {}
    if(state.sorted.length > 0){
      sorted = state.sorted[0].id
    }
    if(state.sorted.length > 0){
      if(state.sorted[0].desc === false){
        order = 1
      }else{
        order = -1
      }
    }
    if(state.filtered.length > 0){
      state.filtered.forEach(element => {
        filtered[element.id] = element.value;
      });
    }

    let admin_id = this.props.user.user_id
    console.log('admin_id',this.props.user);
    this.props.listActiveDevotee({
      admin_id:admin_id,
      page: state.page,
      page_size: state.pageSize,
      sort_param: sorted,
      order: order,
      filtred : filtered
    },this.props.history);
  }
  createTablecontent(data){
    console.log("2",this.state,data);
    
    let ths = this;
    return (data.length>0)?data.map((prop, key) => {
        return {
          sr_no : (this.state.pagesize * this.state.page)+key+1,
          user_id: prop['user_id'],
          state: prop['state'],
          country: prop['country'], 
          totalsession: prop['totalsession'],
          user_totalmantra: prop['user_totalmantra'],
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a like kind of action */}
              {" "}
            </div>
          )
        };
      }):[]
  }
  render() {
    const { classes } = this.props;
    const { activedevotee_total_list_user,activedevotee_total_user } = this.props;
    return (
      <div>
        <GridContainer>                   
          <GridItem xs={12} sm={8} md={8} lg={8}>
            <Card>
            <CardBody color="rose" stats icon>
                <GridContainer>
                    <GridItem xs={4} sm={4} md={4} lg={4}>
                        <h3 className={classes.cardTitle}>Active</h3>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4} lg={4}>
                        <h3 className={classes.cardTitle}>
                            {this.props.activedevotee_total_list_user}
                        </h3>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4} lg={4}>
                   
                        <NavLink to={"/admin/active-devotee-statistics"} className={classes.navLink}>
                            
                            <Button className={classes.marginRight}>
                                Stats <KeyboardArrowRight className={classes.icons} />
                            </Button>
                        </NavLink>
                    </GridItem>
                </GridContainer>
              </CardBody>                
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
             
              <CardHeader color="rose" icon>
                <GridContainer>
                <GridItem xs={9} md={9} lg={9}>
                  <CardIcon color="rose">
                    <PeopleIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Active Devotee</h4>
                  </GridItem>
                  <GridItem xs={3} md={3} lg={3}>
                 
                  </GridItem>
                  </GridContainer>
                </CardHeader>
              <CardBody>         
              <ReactTable
                    data={this.createTablecontent(this.props.activedevotee_list)}
                    filterable
                    columns={[
                      {
                        Header: "Name",
                        accessor: "user_id",
                        sortable : false,
                        filterable : false
                      },                      
                      {
                        Header: "State",
                        accessor: "state",
                        sortable : false,
                        filterable : false
                      }, 
                      {
                        Header: "Country",
                        accessor: "country",
                        sortable : false,
                        filterable : false
                      },
                      {
                        Header: "Total Session",
                        accessor: "totalsession",
                        sortable : false,
                        filterable : false
                      },
                      {
                        Header: "Total Mantra",
                        accessor: "user_totalmantra",
                        sortable : false,
                        filterable : false
                      },
                      {
                        Header: "",
                        accessor: "actions",
                        sortable : false,
                        filterable : false
                      }

                    ]}
                    defaultPageSize={(this.props.activedevotee_list.length<this.state.pagesize)?((this.props.activedevotee_list.length > 0)?this.props.activedevotee_list.length:this.state.pagesize):this.state.pagesize}
                    pages={Math.ceil(this.props.activedevotee_total_user/this.state.pagesize)}               
                    showPaginationBottom={true}                    
                    className="-striped -highlight"
                    resizable={false}
                    manual
                    onFetchData={(state, instance) => {
                      // show the loading overlay                        
                      // fetch your data   
                      console.log("STATEL:",state) 
                      this.setState({page:state.page,pagesize : state.pageSize})
                      this.getDataFromDb(state);
                    }}
                  />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
       </div>
    );
  }
}

ActiveDevotee.propTypes = {
  classes: PropTypes.object
};



 const mapStateToProps = state => {
//   console.log('Active devotee:',state);
  
  return {
    user: state.authReducer.user,
    dashboard_count  : state.authReducer.dashboard_count,
    activedevotee_list : state.activedevoteeReducer.activedevotee_list,    
    activedevotee_total_list_user : state.activedevoteeReducer.activedevotee_total_list_user,
    activedevotee_total_user : state.activedevoteeReducer.activedevotee_total_user,
    shownotification: state.activedevoteeReducer.shownotification,
    activedevotee_error : state.activedevoteeReducer.activedevotee_error,    
    notification_message:state.activedevoteeReducer.notification_message
  };
};
const mapDispatchToProps = { resetActiveDevoteeNotification,listActiveDevotee };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles,bottonstyle)(ActiveDevotee));