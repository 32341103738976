import React, { Component } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Slide from "@material-ui/core/Slide";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Contacts from "@material-ui/icons/Contacts";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";


import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";import { getAllCategory,listSubCategory,changeSubCategoryStatus,addSubCategory,editSubCategory,resetSubCategoryNotification } from '../../redux/action'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};


class SubCategoryList extends Component{
    constructor(props){
        super(props);
        this.setAddCategoryModal = this.setAddCategoryModal.bind(this);
        this.setCategoryname = this.setCategoryname.bind(this);
        this.setCategorynameState = this.setCategorynameState.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.handleSimple = this.handleSimple.bind(this);
        this.state = { 
            data : [],
            addCategoryModal: false,
            categoryName: "",
            categoryNameState : "",
            categories : [],
            totalcategory : 0,
            page : 0,
            pagesize : 5,
            iseditModal : false,
            categoryIdModal: 0,
            main_category_id : 0,
            alert: null,
            all_categories : []
        }
    }
    static getDerivedStateFromProps(props, state) {
      // ...
      // console.log("props cattt",props);
      // console.log("props state cattt",state);
      // if (props.id !== state.prevId) {        
        return {        
          categories: props.subcatogories,
          totalcategory : props.total_sub_category,
          all_categories : props.all_categories       
        };   
      // }    
      // No state update necessary   
      // return null;    
    }
    componentDidMount(){
        // this.setData(dataTable);
        // this.props.listSubCategory();
        this.props.getAllCategory();
    }
    setCategoryname(val=""){
        this.setState({categoryName:val});
    }
    setCategorynameState(val=""){
        this.setState({categoryNameState:val});
    }
    setAddCategoryModal(val=false){
        this.setState({addCategoryModal:val,iseditModal:false,categoryIdModal:0,categoryName:"",main_category_id:0})
    }
    setEditCategoryModal(val="",id=0,main_category_id=0){
      this.setState({
        addCategoryModal: true,
        iseditModal : true,
        categoryName : val,
        categoryIdModal : id,
        main_category_id:main_category_id
      })
    }
    verifyCategoryname(val){
        if(val.trim().length > 0){
            return true;
        }
        return false;
    }
    setAlert(val=null){
      this.setState({alert:val})
    }
    setSimpleSelect(val=0){
      this.setState({main_category_id:val})
    }
    handleSimple(event){
      this.setSimpleSelect(event.target.value);
    };
    addCategory(){
      let admin_id = this.props.user.user_id;
        if(this.state.categoryName.trim() === ""){
            this.setCategorynameState("error");
        }
        let cat = {admin_id,subcategory_name:this.state.categoryName,id:this.state.categoryIdModal,category_id:this.state.main_category_id}
        if(this.state.iseditModal){
          this.props.editSubCategory(cat,this.props.history);
        }else{
          this.props.addSubCategory(cat,this.props.history);
        }
        // this.setCategorynameState();
        this.setCategoryname();
        this.setAddCategoryModal();
        this.setState({iseditModal:false,categoryIdModal:0})
    }  
    deleteCategory(id){
      let admin_id = this.props.user.user_id;
      this.props.changeSubCategoryStatus({admin_id,id,status: 2},this.props.history);
      this.setAlert();
      this.getDataFromDb({page: this.state.page,pageSize: this.state.pagesize,sorted:[],filtered:[]});
    } 
    getDataFromDb(state){
      let sorted,order;
      let filtered = {}
      if(state.sorted.length > 0){
        sorted = state.sorted[0].id
      }
      if(state.sorted.length > 0){
        if(state.sorted[0].desc === false){
          order = 1
        }else{
          order = -1
        }
      }
      if(state.filtered.length > 0){
        state.filtered.forEach(element => {
          filtered[element.id] = element.value;
        });
      }

      let admin_id = this.props.user.user_id
      this.props.listSubCategory({
        admin_id:admin_id,
        page: state.page,
        page_size: state.pageSize,
        sort_param: sorted,
        order: order,
        filtred : filtered
      },this.props.history);
    }
    warningWithConfirmAndCancelMessage(id){
      let ths = this;
      const { classes } = this.props;
      this.setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Do you want to delete it, Sure?"
          onConfirm={() => ths.deleteCategory(id)}
          onCancel={() => ths.setAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
        >          
        </SweetAlert>
      );
    };
    createTablecontent(data){
        return (data)?data.map((prop, key) => {
            return {
              sr_no : (this.state.pagesize * this.state.page)+key+1,
              subcategory_id: prop['subcategory_id'],              
              category_name: prop['category_name'],
              subcategory_name: prop['subcategory_name'],
              actions: (
                // we've added some custom button actions
                <div className="actions-right">                 
                  {/* use this button to add a edit kind of action */}
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      this.setEditCategoryModal(prop['subcategory_name'],prop['subcategory_id'],prop['category_id'])
                    }}
                    color="info"
                    className="edit"
                  >
                    <Edit />
                  </Button>{" "}
                  {/* use this button to remove the data row */}
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {                      
                        this.warningWithConfirmAndCancelMessage(prop['subcategory_id'])
                    }}
                    color="danger"
                    className="remove"
                  >
                    <Close />
                  </Button>{" "}
                </div>
              )
            };
          }):[]
    }
    render(){
        const { classes } = this.props;
        return (
            <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                <GridContainer>
                <GridItem xs={9} md={9} lg={9}>
                  <CardIcon color="primary">
                    <Assignment />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Sub Categories</h4>.
                  </GridItem>
                  <GridItem xs={3} md={3} lg={3}>
                  <Button
                    color="info"
                    round
                    className={classes.marginRight}
                    onClick={() => this.setAddCategoryModal(true)}
                    >
                        Add new SubCategory
                    </Button>
                    </GridItem>
                    </GridContainer>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.createTablecontent(this.state.categories)}
                    filterable

                    columns={[
                      {
                        Header: "Sr No.",
                        accessor: "sr_no",
                        filterable: false,
                        searchable:false
                      },
                      {
                        Header: "Sub Category Name",
                        accessor: "subcategory_name"
                      },
                      {
                        Header: "Category Name",
                        accessor: "category_name"
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false
                      }
                    ]}
                    defaultPageSize={(this.state.categories.length<this.state.pagesize)?((this.state.categories.length > 0)?this.state.categories.length:this.state.pagesize):this.state.pagesize}
                    pages={Math.ceil(this.state.totalcategory/this.state.pagesize)}               
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    manual
                    onFetchData={(state, instance) => {
                      // show the loading overlay                        
                      // fetch your data   
                      this.setState({page:state.page,pagesize : state.pageSize})
                      this.getDataFromDb(state);
                    }}
                  />
                </CardBody>
              </Card>
            </GridItem>
            {/* <GridContainer justify="center"> */}
                  <GridItem xs={12} sm={12} md={12} lg={12} className={classes.center}>                    
                    <Dialog
                        classes={{
                            root: classes.center + " " + classes.modalRoot,
                            paper: classes.modal
                        }}
                        open={this.state.addCategoryModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => this.setAddCategoryModal(false)}
                        aria-labelledby="notice-modal-slide-title"
                        aria-describedby="notice-modal-slide-description"
                    >
                        <DialogTitle
                            id="notice-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                        >
                            <Button
                                justIcon
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="transparent"
                                onClick={() => this.setAddCategoryModal(false)}
                            >
                            <Close className={classes.modalClose} />
                            </Button>
                            {/* <h4 className={classes.modalTitle}>{(this.state.iseditModal)?'Edit SubCategory':'Add SubCategory'}</h4> */}
                        </DialogTitle>
                        <DialogContent
                            id="notice-modal-slide-description"
                            className={classes.modalBody}
                        >
                            <Card>
                                <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                    <Contacts />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>{(this.state.iseditModal)?'Edit SubCategory':'Add SubCategory'}</h4>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                    <CustomInput
                                        success={this.state.categoryNameState === "success"}
                                        error={this.state.categoryNameState === "error"}
                                        labelText="Category"
                                        id="category"
                                        formControlProps={{
                                        fullWidth: true
                                        }}
                                        inputProps={{
                                        onChange: event => {
                                            if (this.verifyCategoryname(event.target.value)) {
                                                this.setCategorynameState("success");
                                            } else {
                                                this.setCategorynameState("error");
                                            }
                                            this.setCategoryname(event.target.value);
                                        },
                                        type: "text",
                                        value: this.state.categoryName
                                        }}
                                    />   
                                    <FormControl
                                      fullWidth
                                      className={classes.selectFormControl}
                                    >
                                      <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                      >
                                        Choose Category
                                      </InputLabel>
                                      <Select
                                        MenuProps={{
                                          className: classes.selectMenu
                                        }}
                                        classes={{
                                          select: classes.select
                                        }}
                                        value={this.state.main_category_id}
                                        onChange={this.handleSimple}
                                        inputProps={{
                                          name: "simpleSelect",
                                          id: "simple-select"
                                        }}
                                      >
                                        <MenuItem
                                          disabled
                                          classes={{
                                            root: classes.selectMenuItem
                                          }}
                                        >
                                          Choose Category
                                        </MenuItem>
                                        {(this.state.all_categories.length>0)?this.state.all_categories.map((cat)=><MenuItem
                                          classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                          }}
                                          value={cat.category_id}
                                        >
                                          {cat.category_name}
                                        </MenuItem>):null}                                                                              
                                      </Select>
                                    </FormControl>                                 
                                    <div className={classes.formCategory}>
                                        <small>*</small> Required fields
                                    </div>
                                    <div className={classes.center}>
                                        <Button color="rose" onClick={this.addCategory}>
                                        {(this.state.iseditModal)?'Edit':'Add'}
                                        </Button>
                                    </div>
                                    </form>
                                </CardBody>
                                </Card>

                        </DialogContent>
                        {/* <DialogActions
                            className={
                            classes.modalFooter + " " + classes.modalFooterCenter
                            }
                        >
                            <Button
                            onClick={() => this.setAddCategoryModal(false)}
                            color="info"
                            round
                            >
                            Sounds Good
                            </Button>
                        </DialogActions> */}
                    </Dialog>
                      
                  </GridItem>
            {/* </GridContainer> */}
            {this.state.alert}
            <Snackbar
              place="tr"
              color={(this.props.subcategory_error)?"danger":"info"}
              icon={AddAlert}
              message={`${this.props.notification_message}`}
              open={this.props.shownotification}
              closeNotification={() =>{
                    this.props.resetSubCategoryNotification();
                }}
              close
            />
          </GridContainer> 
        )
    }
}
const mapStateToProps = state => {
    // console.log('in maptoprops:',state.subcategory);
    
    return {
      user: state.authReducer.user,
        shownotification: state.subcategory.shownotification,
        subcatogories : state.subcategory.subcategories,
        subcategory_error : state.subcategory.subcategory_error,
        total_sub_category : state.subcategory.total_sub_category,        
        loginerror : state.authReducer.loginerror,    
        notification_message:state.subcategory.notification_message,
        all_categories : state.categoryReducer.all_categories
    };
  };
  
  // const mapStateToProps = state => (
  //   console.log();
  //   { 
  //   user: state.user,
  //   shownotification: state.shownotification,
  //   loginerror : state.loginerror,    
  //   notification_message:state.notification_message
  //  });
  const mapDispatchToProps = { changeSubCategoryStatus,listSubCategory,editSubCategory,addSubCategory,getAllCategory,resetSubCategoryNotification };
  
  export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SubCategoryList));