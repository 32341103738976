import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import { API_URL } from '../../constants/defaultValues';

import { 
    LIST_ACTIVEDEVOTEE,
    LIST_ACTIVEDEVOTEE_STATISTICS
 } from "../actionconstant";

import { 
    logoutUser,
    listActiveDevoteeFail,
    listActiveDevoteeSuccess,
    listActiveDevoteeStatisticsFail,
    listActiveDevoteeStatisticsSuccess,
    resetActiveDevoteeNotification
 } from '../action';


function* listActiveDevotee({payload}){
    const { history } = payload;
    let User = (localStorage.getItem('user'))?JSON.parse(localStorage.getItem('user')):null;
    let token = (User)?((User.token)?User.token:''):'';
    let headers = { headers: { token: `${token}` } }
    try{
        const response = yield axios.post(`${API_URL}/user/getactivestats`,payload.data,headers)
        if(response.data.status !== -1){
            if(response.data.status){
                yield put(listActiveDevoteeSuccess(response.data))
            }else{
                yield put(listActiveDevoteeFail(response.data))
                yield call(delay,2000);
                yield put(resetActiveDevoteeNotification());
            }
        }else{
            yield put(listActiveDevoteeFail(response.data))
            yield call(delay,2000);
            yield put(resetActiveDevoteeNotification());
            yield put(logoutUser(history))
            history.push('/');
        }
    }catch(error){
        console.log("error",error)
    }
}

function* listActiveDevoteeStatistics({payload}){
    const { history } = payload;
    let User = (localStorage.getItem('user'))?JSON.parse(localStorage.getItem('user')):null;
    let token = (User)?((User.token)?User.token:''):'';
    let headers = { headers: { token: `${token}` } }
    try{
        const response = yield axios.post(`${API_URL}/user/getstats`,payload.data,headers)
        if(response.data.status !== -1){
            if(response.data.status){
                yield put(listActiveDevoteeStatisticsSuccess(response.data))
            }else{
                yield put(listActiveDevoteeStatisticsFail(response.data))
                yield call(delay,2000);
                yield put(resetActiveDevoteeNotification());
            }
        }else{            
            yield put(listActiveDevoteeStatisticsFail(response.data))
            yield call(delay,2000);
            yield put(resetActiveDevoteeNotification());
            yield put(logoutUser(history))
            history.push('/');
        }
    }catch(error){
        console.log("error",error)
    }
}

export function* watchListDevoteeList(){
    yield takeEvery(LIST_ACTIVEDEVOTEE,listActiveDevotee)
}

export function* watchListDevoteeStatistics(){
    yield takeEvery(LIST_ACTIVEDEVOTEE_STATISTICS,listActiveDevoteeStatistics)
}

const delay = time => new Promise(resolve => setTimeout(resolve, time));

export default function* rootSaga(){
    yield all([
        fork(watchListDevoteeList),
        fork(watchListDevoteeStatistics)
    ])
}