import { 
    LIST_ACTIVEDEVOTEE,
    LIST_ACTIVEDEVOTEE_SUCCESS,
    LIST_ACTIVEDEVOTEE_FAIL,
    LIST_ACTIVEDEVOTEE_STATISTICS,
    LIST_ACTIVEDEVOTEE_STATISTICS_SUCCESS,
    LIST_ACTIVEDEVOTEE_STATISTICS_FAIL,
    RESET_ACTIVEDEVOTEE_NOTIFICATION
} from '../actionconstant'


export const listActiveDevotee = (data,history) => ({
    type : LIST_ACTIVEDEVOTEE,
    payload : {data,history}
})

export const listActiveDevoteeSuccess = (data) => ({
    type : LIST_ACTIVEDEVOTEE_SUCCESS,
    payload : data
})

export const listActiveDevoteeFail = (data) => ({
    type : LIST_ACTIVEDEVOTEE_FAIL,
    payload : data
})

export const listActiveDevoteeStatistics = (data,history) => ({
    type : LIST_ACTIVEDEVOTEE_STATISTICS,
    payload : {data,history}
})

export const listActiveDevoteeStatisticsSuccess = (data) => ({
    type : LIST_ACTIVEDEVOTEE_STATISTICS_SUCCESS,
    payload : data
})

export const listActiveDevoteeStatisticsFail = (data) => ({
    type : LIST_ACTIVEDEVOTEE_STATISTICS_FAIL,
    payload : data
})

export const resetActiveDevoteeNotification = () => ({
    type : RESET_ACTIVEDEVOTEE_NOTIFICATION
})